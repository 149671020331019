import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import p1 from 'assests/Photos/Clubs/MathClub/2023/Illusions/1.jpg';
import p2 from 'assests/Photos/Clubs/MathClub/2023/Illusions/2.jpg';
import p3 from 'assests/Photos/Clubs/MathClub/2023/Illusions/3.jpg';
import p4 from 'assests/Photos/Clubs/MathClub/2023/Illusions/4.jpg';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import { SidebarMath } from './components';
import Archive2022 from 'views/MathClub/components/Archive2022';


const MathClub2023 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 1,
            cols: 1,
        },
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 1,
        },
        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 1,
        },
    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                    Mathematics Illusions
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Classes: 6-8   Date: 10 AUGUST 2023(Thursday)
                                </Typography>

                                <br />
                                <Typography
                                    variant={'h7'}
                                    color={'primary'}
                                    align={'center'}
                                    fontWeight={'normal'}
                                ><br />
                                    “The essence of Math is not to make simple things complicated, but to make complicated things simple”
                                    <br />
                                    <br></br>
                                </Typography>
                                <Typography variant={'subtitle1'} align={'justify'}>
                                    Mathematics Association organized the activity on “MATHEMATICS ILLUSIONS” on 10 August 2023 for the students of classes 6 to 8. Drawing and painting promotes self-esteem and self-expression. It not only helps their creative development but it also stimulates their brain.
                                    <br></br>
                                    The activity aimed to raise awareness among children about illusions combined with mathematical concepts. The objective was impressively attained as we witnessed students designing and drawing beautiful patterns related to mathematical concepts as illusions with a unique and imaginative twist of their own. The activity was also a lesson on the importance of effective teamwork. The team which displayed the best planning and coordination came out with the most beautiful presentation.
                                    <br></br>
                                    It was truly an overall enriching and innovative learning experience for the students.
                                </Typography>
                            </Box>
                            <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                “The study of mathematics, like the Nile, begins in minuteness”
                                <br />
                            </Typography>

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 300 : 200}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box marginBottom={4}>
                            <SidebarMath/>
                        </Box>
                        <Box marginBottom={4}>
                            <Archive2022/>
                        </Box>


                        {/* <SidebarNewsletter /> */}
                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default MathClub2023;